import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert} from "react-bootstrap";
import {Form} from "semantic-ui-react";
import {Button} from "semantic-ui-react";
import {db} from "../firebase-config";
function Verification() {
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // Authenticate the user with the provided username and password
    db.collection("MembersAgreement")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          setSuccess("Details Found");
          navigate(
            `/verified_profile/?contact=${contact}&mail=${email}&authid=Tswe2Fed0oiWs`
          );
        } else {
          // Invalid credentials
          setError("Invalid credentials");
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
      });
  };

  return (
    <>
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div class="card mb-3">
                <div class="card-body">
                  <div
                    class="pb-2"
                    style={{marginBottom: "15px", textAlign: "center"}}
                  >
                    <h5 class="card-title text-center pb-0 fs-3">
                      Login to Your Account
                    </h5>
                    <b>Former Member's Portal</b>
                  </div>
                  {success && (
                    <Alert
                      variant="success"
                      style={{fontSize: "13.5px", textAlign: "center"}}
                    >
                      {success}
                    </Alert>
                  )}
                  {error && (
                    <Alert
                      variant="danger"
                      style={{fontSize: "13.5px", textAlign: "center"}}
                    >
                      {error}
                    </Alert>
                  )}
                  <form class="row g-3 needs-validation" onSubmit={handleLogin}>
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Email
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="email"
                          placeholder="Enter your registered email"
                          value={email}
                          onChange={handleUsernameChange}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">
                        Contact No.
                      </label>
                      <div class="input-group has-validation">
                        <input
                          placeholder="Enter your contact no."
                          value={contact}
                          type="tel"
                          onChange={handleContactChange}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12 my-4">
                      {loader === false ? (
                        <>
                          <Button
                            color="linkedin"
                            style={{width: "100%"}}
                            type="submit"
                          >
                            Verify
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            class="ui loading button"
                            style={{height: "37px", width: "100%"}}
                          >
                            Loading
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Verification;
