import React, {useEffect, useState} from "react";
import "./style.css";
import {useLocation} from "react-router-dom";
import {db} from "../firebase-config";
import {Table} from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function Profile() {
  const [userData, setUserData] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const email = params.get("mail");
  const contact = params.get("contact");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const querySnapshot = await db
          .collection("MembersAgreement")
          .where("email", "==", email)
          .get();

        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });

        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [email]);

  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Agreement Form
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {userData ? (
                userData.map((user) => (
                  <div
                    className="container my-2"
                    key={user.id}
                    style={{
                      border: "1.5px solid grey",
                      padding: "30px 18px 30px 18px",
                    }}
                  >
                    <img
                      style={{width: "100%"}}
                      src="https://firebasestorage.googleapis.com/v0/b/recruitment-1f4e0.appspot.com/o/header.png?alt=media&token=43662d91-1f95-4716-a2a0-87d0cb04e4b1"
                    />
                    <hr></hr>
                    <div>
                      <h1 style={{textAlign: "center", marginTop: "5vh"}}>
                        Wake With Wellness Member’s Agreement
                      </h1>
                      <br></br>

                      <b style={{textAlign: "left"}}>Date: {user.date}</b>
                    </div>

                    <h2
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%) rotate(90deg)",
                        color: "rgb(255 0 0 / 20%)",
                        fontSize: "30vh",
                        fontWeight: "bold",
                      }}
                    >
                      {user.validity}
                    </h2>

                    <div className="para my-4">
                      <p>
                        This form serves as an agreement of the clauses required
                        to be a part of WakeWithWellness , registered under
                        United Nations. The other information is collected for
                        our team database. This information will be kept
                        confidential and will not be shared with any one else.
                      </p>
                      <br></br>
                      <div className="row">
                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Full name :
                        </div>
                        <div className="col-lg-6">
                          <b>{user.name}</b>
                        </div>

                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          E-mail ID :
                        </div>
                        <div className="col-lg-6">
                          <b>{user.email}</b>
                        </div>

                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Contact No. :
                        </div>
                        <div className="col-lg-6">
                          <b>{user.phone}</b>
                        </div>

                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Whatsapp No. :
                        </div>
                        <div className="col-lg-6">
                          <b>**********</b>
                        </div>

                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Alternate Contact Number (State your relation with the
                          person) :
                        </div>
                        <div className="col-lg-6">
                          <b>**********</b>
                        </div>

                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Department :
                        </div>
                        <div className="col-lg-6">
                          <b>{user.department}</b>
                        </div>

                        <div className="col-lg-6 my-2">
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>{" "}
                          Name of institution you are currently enrolled in /
                          workplace :
                        </div>
                        <div className="col-lg-6">
                          <b>{user.college}</b>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div
                      classname="declarartion "
                      style={{textAlign: "justify"}}
                    >
                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        I understand that all the funds raised/ donated will be
                        used only for WakeWithWellness (after getting approval
                        from the Head of Finance) and not for any other reasons.
                        If found indulging in such activity, I recognize that
                        will be the reason for immediate removal from
                        WakeWithWellness, will have to compensate the entire
                        amount of misused finances, and will face the relevant
                        consequences.
                      </p>

                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        I understand that I should maintain confidentiality of
                        plans, ideas, resources, materials, etc. discussed/used
                        amongst the team. If I wish to implement the
                        aforementioned elsewhere or share them with a third
                        party not affiliated with WakeWithWellness, I will get
                        approval from the HR and Administrative head first.
                        After getting approval, I will provide due credit per
                        the standards of WakeWithWellness, if this is
                        implemented or shared elsewhere. I recognize that if I
                        breach this clause, I can be removed from
                        WakeWithWellness immediately and will face the relevant
                        consequences. I understand that this clause stands even
                        after leaving WakeWithWellness that the material can not
                        be shared with any third party without approval, and I
                        will not engage in such practices.
                      </p>
                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        If at any point of time I want to discontinue working
                        for my position, I will inform my CBO’s HR Head or
                        respective Department Head , minimum 2 weeks prior and I
                        will continue working in my position for these 2 weeks.
                        The only exception to this clause is immediate
                        termination of position in case of an emergency as
                        determined by WakeWithWellness. I recognize that reasons
                        such as exams, vacations, packed schedules, etc. do not
                        stand ground as an emergency and I will continue working
                        for the remaining 2 weeks till the termination of the
                        position. Note: You can take breaks as and when
                        required, but the HR or respective Departmental Head
                        should be informed minimum 1 week in advance depending
                        on the nature of the leave.
                      </p>

                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        I will work to the best of my ability. I recognize that
                        continuous negligence of responsibilities, deadlines and
                        confidentiality rules will result in my removal from
                        WakeWithWellness
                      </p>

                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        I understand that if I am found indulging in
                        malpractices of any sort, spreading misinformation about
                        WakeWithWellness and our work, I will be removed from
                        WakeWithWellness immediately and will face the relevant
                        consequences.
                      </p>

                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        I will respect all individuals and their preferred
                        identity, be inclusive in my position, conduct all my
                        responsibilities with dedication, transparency and
                        integrity.
                      </p>

                      <p>
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                        I will abide the code of ethics and professional conduct
                        of WakeWithWellness and United Nations
                      </p>
                    </div>

                    <div style={{marginTop: "10vh"}}>
                      <div className="col-3">
                        <img
                          style={{width: "100%"}}
                          src="https://firebasestorage.googleapis.com/v0/b/recruitment-1f4e0.appspot.com/o/Sudhanshu.png?alt=media&token=73f9991d-bc95-437a-83f7-22a0c099ddcahttps://firebasestorage.googleapis.com/v0/b/recruitment-1f4e0.appspot.com/o/Sudhanshu.png?alt=media&token=44429566-8974-49ef-a002-45c183743770"
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModalA"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {userData ? (
          userData.map((user) => (
            <div class="modal-dialog modal-dialog-centered" key={user.id}>
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    E-Identity Card
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  {user.idcard === undefined || user.idcard === null ? (
                    <p style={{color: "red", textAlign: "center"}}>
                      ID Card not generated
                    </p>
                  ) : (
                    <>
                      <h2
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "#ff00006e",
                          fontSize: "11vh",
                          fontWeight: "bold",
                        }}
                      >
                        {user.validity}
                      </h2>
                      <img
                        src={user.idcard}
                        style={{width: "100%"}}
                        alt="idcard"
                      />
                    </>
                  )}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {/* //////////////MODALS///////////////////// */}
      <div className="container" style={{marginTop: "15vh"}}>
        <div className="main-body">
          {userData ? (
            userData.map((user) => (
              <div className="row gutters-sm" key={user.id}>
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        {user.img == null ? (
                          <>
                            <img
                              src={user.photo}
                              alt="Admin"
                              style={{
                                width: "20vh",
                                borderRadius: "8px",
                                height: "22vh",
                                objectFit: "cover",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={user.img}
                              alt="Admin"
                              className="rounded-circle"
                              width="150"
                            />
                          </>
                        )}

                        <div className="mt-3">
                          <h4>{user.name}</h4>
                          <p className="text-secondary mb-1">{user.phone}</p>
                          <p className="font-size-sm">{user.department}</p>
                          <button
                            type="button"
                            style={{backgroundColor: "#1f88be", color: "white"}}
                            class="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Agreement
                          </button>
                          <button
                            type="button"
                            style={{
                              border: "1px solid #1f88be",
                              color: "#1f88be",
                            }}
                            class="btn ms-1"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalA"
                          >
                            ID Card
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-3 my-2">
                          <h6 className="mb-0">Full Name:</h6>
                        </div>
                        <div className="col-sm-9 my-2 text-secondary">
                          {user.name}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 my-2">
                          <h6 className="mb-0">Email:</h6>
                        </div>
                        <div className="col-sm-9 my-2 text-secondary">
                          {user.email}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 my-2">
                          <h6 className="mb-0">Contact:</h6>
                        </div>
                        <div className="col-sm-9 my-2 text-secondary">
                          {user.phone}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 my-2">
                          <h6 className="mb-0">Blood Group:</h6>
                        </div>
                        <div className="col-sm-9 my-2 text-secondary">
                          {user.blood}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 my-2">
                          <h6 className="mb-0">
                            Department (IInd Preference):
                          </h6>
                        </div>
                        <div className="col-sm-9 my-2 text-secondary">
                          {user.department2}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 my-2">
                          <h6 className="mb-0">Joining Date:</h6>
                        </div>
                        <div className="col-sm-9 my-2 text-success">
                          {user.date}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Tenure Certificates</h5>
                      <Table responsive striped bordered size="sm">
                        <thead
                          style={{
                            backgroundColor: "rgb(31, 136, 190)",
                            color: "white",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>Year</th>
                            <th>Remark</th>
                            <th style={{textAlign: "center"}}>Certifictae</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!user.y1 ? (
                            <></>
                          ) : (
                            <>
                              <tr>
                                <td style={{width: "3vh"}}>
                                  <b>1</b>
                                </td>
                                <td style={{width: "20vh"}}>{user.y1}</td>
                                <td>{user.r1}</td>

                                <td
                                  style={{width: "12vh", textAlign: "center"}}
                                >
                                  <a
                                    href={user.c1}
                                    target="_blank"
                                    style={{
                                      color: "rgb(31, 136, 190)",
                                    }}
                                  >
                                    <i class="fa fa-download"></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          )}

                          {!user.y2 ? (
                            <></>
                          ) : (
                            <>
                              <tr>
                                <td style={{width: "3vh"}}>
                                  <b>2</b>
                                </td>
                                <td style={{width: "20vh"}}>{user.y2}</td>
                                <td>{user.r2}</td>

                                <td
                                  style={{width: "12vh", textAlign: "center"}}
                                >
                                  <a href={user.c2} target="_blank">
                                    <i
                                      style={{
                                        color: "rgb(31, 136, 190)",
                                      }}
                                      class="fa fa-download"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Profile;
