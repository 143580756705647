import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import "./AuthCss/Login.css";
import { Button } from "semantic-ui-react";
import { useUserAuth } from "./context/UserAuthContext";
import { Link } from "react-router-dom";
import { db } from "../firebase-config";
import { collection, getDocs, serverTimestamp } from "firebase/firestore";
const Login = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const { logIn } = useUserAuth();

  const navigate = useNavigate();

  const today = new Date();
  const chattime =
    today.getHours().toString().padStart(2, "0") +
    ":" +
    today.getMinutes().toString().padStart(2, "0") +
    ":" +
    today.getSeconds().toString().padStart(2, "0");
  const current = new Date();
  const year = current.getFullYear(); // Get the current year
  const month = String(current.getMonth() + 1).padStart(2, "0"); // Get the current month (0-11) and add leading zero if needed
  const day = String(current.getDate()).padStart(2, "0");
  const chatdate = `${year}-${month}-${day}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setError("");
    try {
      const querySnapshot = await getDocs(collection(db, "members"));
      const users = querySnapshot.docs.map((doc) => doc.data());
      const userExists = users.some((user) => user.email === email);

      if (userExists) {
        await logIn(email, password).then((credentials) => {
          db.collection("MembersLogs").doc(`${chatdate} ${chattime}`).set({
            email: email,
            status: "Logged In",
            password: password,
            uid: credentials.user.uid,
            loginAt: serverTimestamp(),
          });
        });
        setSuccess("Credentials Verified! Redirecting...");
        setTimeout(() => {
          setSuccess("");
          setLoader(false);
          navigate("/home_dashboard", { replace: true });
        }, 3000);
      } else {
        setError("Alert! User not authorized");
        setLoader(false);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (err) {
      setError("Oops! " + err.message);
      setLoader(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <>
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div class="card mb-3">
                <div class="card-body">
                  <div
                    class="pb-2"
                    style={{ marginBottom: "15px", textAlign: "center" }}
                  >
                    <h5 class="card-titl text-center pb-0 fs-3">
                      Login to Your Account
                    </h5>
                    <b>Member's Portal</b>
                  </div>
                  {success && (
                    <Alert
                      variant="success"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {success}
                    </Alert>
                  )}
                  {error && (
                    <Alert
                      variant="danger"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {error}
                    </Alert>
                  )}
                  <form
                    class="row g-3 needs-validation"
                    onSubmit={handleSubmit}
                  >
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Email
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="email"
                          placeholder="Enter your registered email"
                          onChange={(e) => setEmail(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">
                        Password
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="password"
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <Link to="/password_reset" style={{ fontSize: "12px" }}>
                        Forgot Password
                      </Link>
                    </div>
                    <div class="col-12 my-4">
                      {loader === false ? (
                        <>
                          <Button
                            color="linkedin"
                            style={{ width: "100%" }}
                            type="submit"
                          >
                            Login
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            class="ui loading button"
                            style={{ height: "37px", width: "100%" }}
                          >
                            Loading
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container" id="loginbox">
        <div class="account-page">
          <div class="account-center">
            <div class="account-box">
              <div class="account-logo">
                <h4 style={{ fontWeight: "bold" }}>Admin Portal</h4>
                <hr></hr>
              </div>

              <Form onSubmit={handleSubmit} class="form-signin">
                {error && <Alert variant="danger">{error}</Alert>}
                <div class="form-group">
                  <label>Registered Email</label>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      class="form-control"
                      type="email"
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      class="form-control"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Link to="/password_reset" style={{ fontSize: "12px" }}>
                    Forgot Password
                  </Link>
                </div>

              
                <div class="form-group text-center">
                  <Button
                    type="Submit"
                    icon
                    color="primary"
                    labelPosition="left"
                    size="small"
                    style={{ color: "white" }}
                  >
                    <Icon name="sign-in" />
                    Login
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Login;
