import React, {useState} from "react";
import {Form, Button} from "semantic-ui-react";
import {db} from "./firebase-config";
import {Alert} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {serverTimestamp} from "firebase/firestore";

function Tshirt() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [size, setSize] = useState("");
  const [tname, setTname] = useState("");
  const [address, setAddress] = useState("");

  const [loader, setLoader] = useState(false);
  const [success, setsuccess] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    setsuccess("");

    // Create a new document in the "submissions" collection
    db.collection("Tshirts")
      .add({
        name,
        email,
        phone,
        size,
        address,
        tname,
        status: "Pending",
        postedOn: serverTimestamp(),
      })
      .then(() => {
        console.log("Form submitted successfully!");
        setsuccess("Submitted Successfully!");
        setLoader(false);
        // Perform any additional actions after successful form submission
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });

    // Reset the form fields
    setName("");
    setEmail("");
    setPhone("");
    setTname("");
    setAddress("");
    setSize("");

    setTimeout(() => {
      setsuccess("");
    }, 4000);
  };

  const [inputValue, setInputValue] = useState("7086952212-1@okbizaxis");
  const [copy, setCopied] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCopyClick = () => {
    // Copy the input value to the clipboard
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        // Alert the user that the value has been copied
        setCopied("UPI ID copied");
        setTimeout(() => {
          setCopied("");
        }, 3000);
      })
      .catch((error) => {
        console.error("Error copying value:", error);
      });
  };

  return (
    <>
      <div class="container " style={{marginTop: "10vh"}}>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">WWW T-Shirt</h5>
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-6">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/recruitment-1f4e0.appspot.com/o/black-t-shirts-with-copy-space.png?alt=media&token=d6609de6-1af9-4660-bef1-5937130d7523"
                    alt="tshirt"
                    style={{width: "100%", height: "100%"}}
                  />
                </div>
                <div class="col-lg-6 my-2">
                  <h5>
                    <h4>Details</h4>
                    <ul>
                      <li>
                        T-shirt price: <b style={{color: "red"}}>₹410.00</b>
                      </li>
                      <li>180 GSM Biowashed cotton</li>
                    </ul>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container ">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Fill your details</h5>

            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 my-2">
                  <Form.Input
                    label="Full Name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your full name"
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <Form.Input
                    label="Email"
                    required
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email id"
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <Form.Input
                    label="Contact"
                    required
                    value={phone}
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Your contact no."
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <Form.Input
                    label="T-shirt Name"
                    required
                    value={tname}
                    type="text"
                    onChange={(e) => setTname(e.target.value)}
                    placeholder="Your tshirt display name"
                  />
                </div>

                <div className="col-lg-6 my-2">
                  <Form.TextArea
                    label="Address"
                    required
                    value={address}
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Your current address"
                  ></Form.TextArea>
                </div>

                <div className="col-lg-6 my-2">
                  <Form.Field
                    label="Tshirt Size"
                    value={size}
                    required
                    onChange={(e) => setSize(e.target.value)}
                    control="select"
                  >
                    <option value="">--Select size--</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                  </Form.Field>
                </div>
              </div>

              {success && (
                <Alert
                  variant="success"
                  style={{fontSize: "12px", textAlign: "center"}}
                >
                  {success}
                </Alert>
              )}
              <div className="text-center my-5">
                {loader === false ? (
                  <>
                    <Button color="linkedin" type="submit">
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <button class="ui loading button" style={{height: "37px"}}>
                      Loading
                    </button>
                  </>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Payment Method</h5>

            <p>
              *Payment Amount: <b>₹410.00</b>
            </p>
            {copy && (
              <Alert
                variant="success"
                style={{fontSize: "13.5px", textAlign: "center"}}
              >
                {copy}
              </Alert>
            )}
            <div class="container-fluid">
              <div class="row">
                <div class="col-8 my-2">
                  <Form.Input
                    type="text"
                    style={{width: "100%"}}
                    value={inputValue}
                    readOnly
                    onChange={handleInputChange}
                  />
                </div>
                <div class="col-4 my-2">
                  <Button onClick={handleCopyClick}>Copy</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </>
  );
}

export default Tshirt;
