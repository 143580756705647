import React from "react";

function Header() {
  return (
    <>
      <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
          <a class="logo d-flex align-items-center" style={{minWidth: "50vh"}}>
            <span class="d-none d-lg-block"> WAKE WITH WELLNESS</span>
            <span
              class="d-lg-none"
              style={{textAlign: "center", fontSize: "20px"}}
            >
              WAKE WITH WELLNESS
            </span>
          </a>
        </div>
      </header>
    </>
  );
}

export default Header;
