import React from "react";
import "./Style.css";
import { Link } from "react-router-dom";

import GoToTop from "../GoToTop";

const Home = () => {
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  const currentMonth = `${month} ${year}`;
  return (
    <>
      <GoToTop />

      <div
        class="container-fluid"
        style={{ marginTop: "10vh", maxWidth: "190vh", textAlign: "center" }}
      >
        <h3 style={{ color: "#1f88be", fontWeight: "800", fontSize: "30px" }}>
          WWW Portal
        </h3>
        <p style={{ fontSize: "14px", color: "grey", marginTop: "-5px" }}>
          <span style={{ fontWeight: "500" }}>Wake With Wellness</span> is a
          Community Based Organization registered under{" "}
          <span style={{ fontWeight: "500" }}>UNITED NATIONS</span> with a
          vision of steering people for a hale and hearty life form,{" "}
          <span style={{ fontWeight: "500" }}>
            ‘We Are People Caring For People’
          </span>{" "}
        </p>
      </div>

      <div class="container-fluid my-2" style={{ maxWidth: "190vh" }}>
        <div class="row">
          <div class="col">
            <div
              class="card"
              style={{
                backgroundColor: "#fff",
                borderTop: "3px solid #1f88be",
              }}
            >
              <h1 style={{ textAlign: "center" }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/volunteers-2ddcc.appspot.com/o/member.jpg?alt=media&token=3b2b29ab-bd0c-46d5-b7ea-4bbc8fde2692"
                  style={{ width: "20vh", height: "auto" }}
                  class="card-img-top"
                  alt="..."
                />
              </h1>
              <div
                class="card-body"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <h5
                  class="card-titl"
                  style={{ color: "#212A3E", fontSize: "15px" }}
                >
                  Member
                </h5>
                <p class="card-text"></p>
                <Link
                  to="/home_dashboard"
                  style={{
                    width: "120px",
                    backgroundColor: "#1f88be",
                    color: "#fff",
                    marginTop: "-12px",
                  }}
                  class="btn"
                >
                  <i class="fa fa-sign-in" aria-hidden="true"></i> Login
                </Link>
              </div>
            </div>
          </div>

          {/* <div class="col">
            <div
              class="card"
              style={{backgroundColor: "#fff", borderTop: "3px solid #1f88be"}}
            >
              <h1 style={{textAlign: "center"}}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/volunteers-2ddcc.appspot.com/o/volunteers.png?alt=media&token=b45b380b-bd39-47d4-b00f-f716eb9cc557"
                  style={{width: "20vh", height: "auto"}}
                  class="card-img-top"
                  alt="..."
                />
              </h1>
              <div
                class="card-body"
                style={{textAlign: "center", marginTop: "-20px"}}
              >
                <h5
                  class="card-title"
                  style={{color: "#212A3E", fontSize: "15px"}}
                >
                  Coordinator
                </h5>
                <p class="card-text"></p>
                <Link
                  to="/coordinators_authenticate"
                  style={{
                    width: "120px",
                    backgroundColor: "#1f88be",
                    color: "#fff",
                    marginTop: "-12px",
                  }}
                  class="btn"
                >
                  <i class="fa fa-sign-in" aria-hidden="true"></i> Login
                </Link>
              </div>
            </div>
          </div> */}

          <div class="col">
            <div
              class="card"
              style={{
                backgroundColor: "#fff",
                borderTop: "3px solid #1f88be",
              }}
            >
              <h1 style={{ textAlign: "center" }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/volunteers-2ddcc.appspot.com/o/admin.png?alt=media&token=7dccbcfb-3ed5-41cf-a881-8954548ea727"
                  style={{ width: "20vh", height: "auto" }}
                  class="card-img-top"
                  alt="..."
                />
              </h1>
              <div
                class="card-body"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <h5
                  class="card-titl"
                  style={{ color: "#212A3E", fontSize: "15px" }}
                >
                  Admin
                </h5>
                <p class="card-text"></p>
                <a
                  href="https://adminportal.wakewithwellness.in/signin"
                  style={{
                    width: "120px",
                    backgroundColor: "#1f88be",
                    color: "#fff",
                    marginTop: "-12px",
                  }}
                  class="btn"
                >
                  <i class="fa fa-sign-in" aria-hidden="true"></i> Login
                </a>
              </div>
            </div>
          </div>

          <div class="col">
            <div
              class="card"
              style={{
                backgroundColor: "#fff",
                borderTop: "3px solid #1f88be",
              }}
            >
              <h1 style={{ textAlign: "center" }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/volunteers-2ddcc.appspot.com/o/member.jpg?alt=media&token=3b2b29ab-bd0c-46d5-b7ea-4bbc8fde2692"
                  style={{ width: "20vh", height: "auto" }}
                  class="card-img-top"
                  alt="..."
                />
              </h1>
              <div
                class="card-body"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <h5
                  class="card-titl"
                  style={{ color: "#212A3E", fontSize: "15px" }}
                >
                  Former Member
                </h5>
                <p class="card-text"></p>
                <Link
                  to="/verifications"
                  style={{
                    width: "120px",
                    backgroundColor: "#1f88be",
                    color: "#fff",
                    marginTop: "-12px",
                  }}
                  class="btn"
                >
                  <i class="fa fa-sign-in" aria-hidden="true"></i> Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                Code of Ethics and Professional Conduct
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                class="content"
                style={{
                  padding: "10px",
                  textAlign: "justify",
                  fontSize: "12px",
                }}
              >
                <p>
                  <i class="fa fa-certificate" aria-hidden="true"></i>{" "}
                  <span style={{ fontWeight: "500" }}>
                    Choose your words carefully :
                  </span>
                  Always conduct yourself professionally. Be kind to others. Do
                  not insult or put down others. Harassment and exclusionary
                  behaviours aren't acceptable. This includes, but is not
                  limited to:
                  <ul>
                    <li>Threats of violence.</li>
                    <li>
                      Discriminatory jokes and language anywhere within the CBO.
                      Non-parliamentary language won’t be entertained in
                      departmental and official whatsapp groups.
                    </li>
                    <li>
                      Sharing sexually explicit or violent material via
                      electronic devices or other means
                    </li>
                    <li>
                      Personal insults, especially those using racist or sexist
                      terms.
                    </li>
                    <li>Unwelcome sexual attention.</li>
                    <li>
                      Advocating for, or encouraging, any of the above
                      behaviours.
                    </li>
                  </ul>
                </p>

                <p>
                  <i class="fa fa-certificate" aria-hidden="true"></i>{" "}
                  <span style={{ fontWeight: "500" }}>
                    Our differences can be our strengths. :
                  </span>{" "}
                  We can find strength in diversity. Different people have
                  different perspectives on issues, and that can be valuable for
                  solving problems or generating new ideas. Being unable to
                  understand why someone holds a viewpoint doesn’t mean that
                  they’re wrong. Don’t forget that we all make mistakes, and
                  blaming each other doesn’t get us anywhere. Instead, focus on
                  resolving issues and learning from mistakes.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid" style={{ maxWidth: "190vh" }}>
        <div class="row">
          <div class="col-lg">
            <div
              class="card"
              style={{
                backgroundColor: "#fff",
                borderTop: "3px solid #1f88be",
              }}
            >
              <div
                class="content"
                style={{
                  backgroundColor: "rgb(229, 229, 229)",
                  padding: "10px",
                }}
              >
                <h5 style={{ fontWeight: "600" }}>Notifications</h5>
              </div>
              <div
                class="content"
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontSize: "12px",

                  textTransform: "uppercase",
                }}
              >
                <p>
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                  <Link to="/attendance_">
                    Mark your attendance for the month of {currentMonth}
                  </Link>
                </p>

                <p>
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/files-bf645.appspot.com/o/wwwportal.apk?alt=media&token=0186b11a-bf07-4224-9a86-afa2473b65c7"
                    target="_blank"
                  >
                    Download our WWW Portal (For android users)
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg">
            <div
              class="card"
              style={{
                backgroundColor: "#fff",
                borderTop: "3px solid #1f88be",
              }}
            >
              <div
                class="content"
                style={{
                  backgroundColor: "rgb(229, 229, 229)",
                  padding: "10px",
                }}
              >
                <h5 style={{ fontWeight: "600" }}>
                  Code of Ethics and Professional Conduct
                </h5>
              </div>
              <div
                class="content"
                style={{
                  padding: "10px",
                  textAlign: "justify",
                  fontSize: "12px",
                }}
              >
                <p>
                  <i class="fa fa-certificate" aria-hidden="true"></i>{" "}
                  <span style={{ fontWeight: "500" }}>Be inclusive :</span> We
                  welcome and support people of all backgrounds and identities.
                  This includes, but is not limited to members of any sexual
                  orientation, gender identity and expression, race, ethnicity,
                  culture, national origin, social and economic class,
                  educational level, colour, immigration status, sex, age, size,
                  family status, political belief, religion, and mental and
                  physical ability.
                </p>

                <p>
                  <i class="fa fa-certificate" aria-hidden="true"></i>{" "}
                  <span style={{ fontWeight: "500" }}>Be considerate :</span> We
                  all depend on each other to produce the best work we can as a
                  community based organisation. Your decisions might affect the
                  masses, and you should take those consequences into account
                  when making decisions
                </p>

                <p>
                  <i class="fa fa-certificate" aria-hidden="true"></i>{" "}
                  <span style={{ fontWeight: "500" }}>Be respectful :</span>
                  We won't all agree all the time, but disagreement is no excuse
                  for disrespectful behaviour. We will all experience
                  frustration from time to time, but we cannot allow that
                  frustration become personal attacks. An environment where
                  people feel uncomfortable or threatened is not a productive or
                  creative one.
                </p>

                <a
                  style={{ float: "right" }}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  More...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
